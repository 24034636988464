
export default {
  name: 'AtomPinVideo',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          videoSrc: ''
        }
      }
    }
  },
  computed: {
    resourceUrl() {
      if (this.pinInfo.videoSrc.includes('://')) {
        return this.pinInfo.videoSrc
      }
      return `${this.$store.getters['base/cdnBase']}/${this.pinInfo.videoSrc}`
    }
  }
}
