
export default {
  name: 'AtomProjectPortfolio',
  props: {
    image: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String,
      default: ''
    },
    subtitle: {
      required: true,
      type: String,
      default: ''
    },
    text: {
      required: true,
      type: String,
      default: ''
    },
    href: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    },
    tabId: {
      required: true,
      type: String,
      default: ''
    },
    projectId: {
      required: true,
      type: String,
      default: ''
    },
    activeProject: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeProject === this.tabId
    },
    resourceUrl() {
      if (!this.isInView) return ''
      return `${this.$store.getters['base/cdnBase']}/${this.$props.image}`
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', {
        tabId: this.tabId,
        projectId: this.projectId
      })
    }
  }
}
