
import AtomPinSimpleText from './AtomPinSimpleText'
import AtomPinIconText from './AtomPinIconText'
import AtomPinVideo from './AtomPinVideo'
import AtomPinImage from './AtomPinImage'

export default {
  name: 'AtomPinInfoContent',
  components: { AtomPinVideo, AtomPinImage, AtomPinIconText, AtomPinSimpleText },
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          type: ''
        }
      }
    }
  },
  data() {
    return {
      componentType: null
    }
  },
  mounted() {
    this.handleType(this.pinInfo.type)
  },
  methods: {
    handleType(pinType) {
      switch (pinType) {
        case 'logoPin':
          this.componentType = AtomPinIconText
          break
        case 'videoPin':
          this.componentType = AtomPinVideo
          break
        case 'imagePin':
          this.componentType = AtomPinImage
          break
        default:
          this.componentType = AtomPinSimpleText
          break
      }
    }
  }
}
