
export default {
  name: 'AtomPinFitoutButtons',
  props: {
    fitouts: {
      required: false,
      type: Array,
      default: function () {
        return [
          {
            name: '',
            buttonText: ''
          }
        ]
      }
    }
  },
  computed: {},
  methods: {
    changeFitout(value) {}
  }
}
