
export default {
  name: 'AtomPinImage',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          imageSrc: ''
        }
      }
    }
  },
  computed: {
    resourceUrl() {
      if (!this.isInView) return ''
      if (this.pinInfo.imageSrc.includes('://')) {
        return this.pinInfo.imageSrc
      }
      return `${this.$store.getters['base/cdnBase']}/${this.pinInfo.imageSrc}`
    }
  }
}
